<template>
  <section class="service_agreement">
    <NavBar />
    <Header type="personalCenter" />
    <div class="content box p300">
      <div v-html="text"></div>
    </div>
  </section>
</template>

<script>
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import {
    getData
  } from 'api/serviceAgreement.js';

  export default {
    components: {
      NavBar,
      Header
    },

    created() {
      this.getData();
    },

    data() {
      return {
        text: ''
      }
    },

    methods: {
      getData() {
        getData({
          agreeType: 9
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.text = res.data;
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .service_agreement {
    .content {
      .title {
        font-size: var(--fontSize24);
        color: var(--color000);
        font-weight: bold;
        text-align: center;
      }
    }
  }
</style>
